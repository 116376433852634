import classNames from "classnames"

const allRacesButtonRowClasses = classNames(`mt-6 md:mt-8`)

interface AllRacesButtonRowProps {
  children: React.ReactNode
}

export default function AllRacesButtonRow({ children }: AllRacesButtonRowProps) {
  return <div className={allRacesButtonRowClasses}>{children}</div>
}
