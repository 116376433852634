import { type RaceOverview } from "../../home/raceOverview"
import { RaceDate } from "../../home/RaceDate"
import { routes } from "../../routes"
import { ButtonLink } from "@website/components/Button/Button"
import RaceCardRow from "@website/components/RaceCardRow/RaceCardRow"
import placeholder from "@website/race/placeholder-large.svg"
import { cloudinarySrcSet } from "@website/components/cloudinarySrcSet"
import RaceTags from "@website/components/RaceTags/RaceTags"
import { Link } from "react-router-dom"
import RaceCardMetaItem from "@website/components/RaceCardMetaItem/RaceCardMetaItem"
import SmallText from "@website/components/SmallText/SmallText"
import Container from "@website/components/Container/Container"
import { Card } from "../Card/Card"
import classNames from "classnames"

const contentClasses = classNames(`grid content-center items-center pb-4 md:py-8`)
const contentFooterClasses = classNames(`px-4 pt-4 md:px-8 md:pt-8`)
const highlightRaceClasses = classNames(`lg:grid lg:grid-cols-2`)
const imageClasses = classNames(`aspect-movie h-full w-full scale-100 object-cover object-center transition-transform duration-500 ease-in-out hover:scale-105 lg:aspect-unset`)
const imageWrapperClasses = classNames(`relative overflow-hidden xs:rounded-t-xl lg:order-2 lg:rounded-l-xl`)
const tagClasses = classNames(`pointer-events-none absolute right-0 top-0 z-10 inline-block rounded-bl-xl bg-brand-primary px-3 py-2 font-bold text-white sm:px-4 sm:py-2 sm:text-lg`)

interface HighlightedRaceProps {
  race: RaceOverview
}

const subracesLimit = 3

export default function HighlightedRace({ race }: HighlightedRaceProps) {
  const showMore = race.subraces.length > subracesLimit
  const subraces = race.subraces.slice(0, Math.min(race.subraces.length, subracesLimit))
  const subracesCount = race.subraces.length - subraces.length
  return (
    <Container>
      <Card marginBottom="small" overflow="hidden">
        <div className={highlightRaceClasses}>
          <div className={imageWrapperClasses}>
            <div className={tagClasses}>Doporučujeme</div>
            <Link to={routes.race.getLink(race.slug)}>
              {race.heroimagepublicid ? (
                <img
                  className={imageClasses}
                  loading="eager"
                  width="765"
                  height="574"
                  alt={race.name}
                  {...cloudinarySrcSet(race.heroimagepublicid, {
                    rules: [
                      { breakPoint: "base", imageWidth: 500, sizeWidthValue: "100vw" },
                      { breakPoint: "large", imageWidth: 700 },
                      { breakPoint: "extraLarge", imageWidth: 900 }
                    ],
                    default: "large",
                    transforms: { quality: "auto", crop: "fill", aspectRatio: "4:3", gravity: "face" }
                  })}
                />
              ) : (
                <img className={imageClasses} loading="eager" width="765" height="574" alt={race.name} src={placeholder} />
              )}
            </Link>
          </div>
          <div className={contentClasses}>
            <RaceTags tags={race.tags} highlighted bordered />
            <RaceCardRow
              to={routes.race.getLink(race.slug)}
              title={race.name}
              lead
              highlighted
              description={
                <ul className="md:mt-1">
                  <RaceCardMetaItem>
                    <strong>
                      <RaceDate raceDate={race.datetime} subraces={race.subraces} />
                    </strong>
                  </RaceCardMetaItem>
                  {race.place && <RaceCardMetaItem separator>{race.place}</RaceCardMetaItem>}
                </ul>
              }
            />

            {subraces.map(subrace => (
              <RaceCardRow highlighted to={routes.race.getLink(race.slug, subrace.slug)} key={subrace.id} title={subrace.name} description={<SmallText>{subrace.shortdescription}</SmallText>} />
            ))}
            {showMore && (
              <RaceCardRow
                to={routes.race.getLink(race.slug)}
                key="more"
                highlighted
                simple
                description={
                  <SmallText>
                    {`+ ${subracesCount} ${(subracesCount === 1 && "další závod") || (subracesCount > 1 && subracesCount <= 4 && "další závody") || (subracesCount > 4 && "dalších závodů")}`}
                  </SmallText>
                }
              />
            )}
            <div className={contentFooterClasses}>
              <ButtonLink size="large" to={routes.race.getLink(race.slug)}>
                Detail závodu
              </ButtonLink>
            </div>
          </div>
        </div>
      </Card>
    </Container>
  )
}
