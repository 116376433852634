import { hasSameDate } from "utils"

export interface SubraceDatesOnly {
  datetimeend: Date | null
  datetimeopening: Date | null
  datetimestart: Date | null
}

interface RaceDateRangeResult {
  endsWithDifferentDay: boolean
  maxEndDate: number | undefined
  minStartDate: number
}

export const calculateRaceDateRange = (raceDate: Date | number, subraces: SubraceDatesOnly[]): RaceDateRangeResult => {
  const raceDateTime = new Date(raceDate).getTime()
  const startDates = subraces.filter(sr => !!sr.datetimeopening).map(sr => new Date(sr.datetimeopening!).getTime())
  const endDates = subraces.filter(sr => !!sr.datetimeend).map(sr => new Date(sr.datetimeend!).getTime())

  const minStartDate = startDates.length > 0 ? Math.min(raceDateTime, ...startDates) : raceDateTime
  const maxEndDate = endDates.length > 0 ? Math.max(...endDates) : raceDateTime

  const endsWithDifferentDay = !hasSameDate(minStartDate, maxEndDate)

  return {
    minStartDate,
    maxEndDate,
    endsWithDifferentDay
  }
}
