import classNames from "classnames"

const raceCardMetaItemClasses = classNames(`inline-block`)
const withSeparatorClasses = classNames(`before:mx-1 before:content-['–']`)

interface RaceCardMetaItemProps {
  children: React.ReactNode
  separator?: boolean
}

export default function RaceCardMetaItem({ children, separator }: RaceCardMetaItemProps) {
  return <li className={classNames(raceCardMetaItemClasses, separator && withSeparatorClasses)}>{children}</li>
}
