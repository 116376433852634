import { type RaceOverview } from "@website/home/raceOverview"
import RaceCardRow from "../RaceCardRow/RaceCardRow"
import { RaceDate } from "@website/home/RaceDate"
import { routes } from "@website/routes"
import { ButtonLink } from "@website/components/Button/Button"
import RaceCardImage from "@website/components/RaceCardImage/RaceCardImage"
import { Card } from "../Card/Card"
import RaceTags from "@website/components/RaceTags/RaceTags"
import SmallText from "../SmallText/SmallText"
import RaceCardMetaItem from "../RaceCardMetaItem/RaceCardMetaItem"
import classNames from "classnames"

const footerClasses = classNames(`mt-auto p-4`)

interface RaceCardProps {
  race: RaceOverview
}

const subracesLimit = 3

export default function RaceCard({ race }: RaceCardProps) {
  const showMore = race.subraces.length > subracesLimit
  const subraces = race.subraces.slice(0, Math.min(race.subraces.length, subracesLimit))
  const subracesCount = race.subraces.length - subraces.length
  return (
    <Card overflow="hidden">
      <RaceCardImage heroImagePublicId={race.heroimagepublicid} to={routes.race.getLink(race.slug)} raceName={race.name} />
      <RaceTags tags={race.tags} bordered />
      <RaceCardRow
        to={routes.race.getLink(race.slug)}
        title={race.name}
        large
        description={
          <ul>
            <RaceCardMetaItem>
              <SmallText>
                <strong>
                  <RaceDate raceDate={race.datetime} subraces={race.subraces} />
                </strong>
              </SmallText>
            </RaceCardMetaItem>
            {race.place && (
              <RaceCardMetaItem separator>
                <SmallText>{race.place}</SmallText>
              </RaceCardMetaItem>
            )}
          </ul>
        }
      />

      {subraces.map(subrace => (
        <RaceCardRow to={routes.race.getLink(race.slug, subrace.slug)} key={subrace.id} title={subrace.name} description={<SmallText>{subrace.shortdescription}</SmallText>} />
      ))}
      {showMore && (
        <RaceCardRow
          to={routes.race.getLink(race.slug)}
          key="more"
          simple
          description={
            <SmallText>
              {`+ ${subracesCount} ${(subracesCount === 1 && "další závod") || (subracesCount > 1 && subracesCount <= 4 && "další závody") || (subracesCount > 4 && "dalších závodů")}`}
            </SmallText>
          }
        />
      )}
      <div className={footerClasses}>
        <ButtonLink to={routes.race.getLink(race.slug)}>Detail závodu</ButtonLink>
      </div>
    </Card>
  )
}
