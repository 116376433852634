import { Helmet } from "react-helmet"

import { getAbsoluteLink } from "@infrastructure/routes/routes"

export const CanonicalUrl = (props: { relativeUrl: string }) => {
  const absoluteUrl = getAbsoluteLink(props.relativeUrl)
  return (
    <Helmet>
      <link rel="canonical" href={absoluteUrl} />
      <meta property="og:url" content={absoluteUrl} />
    </Helmet>
  )
}
