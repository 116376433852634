import Layout from "../layout/Layout/Layout"
import Meta from "../layout/Meta"
import RaceCard from "../components/RaceCard/RaceCard"
import { type RaceOverview } from "./raceOverview"
import webApi from "../webApi"
import { ServerCall } from "@infrastructure/api/serverCall"
import Heading from "../components/Heading/Heading"
import { PageContentLoader } from "../components/PageContentLoader"
import Container from "../components/Container/Container"
import HighlightedRace from "../components/HighlightedRace/HighlightedRace"
import { RaceStatus } from "common"
import { CanonicalUrl } from "../layout/CanonicalUrl"
import { routes } from "../routes"
import { ButtonLink } from "@website/components/Button/Button"
import { initialPropsWrapper } from "@infrastructure/afterjs/initialPropsWrapper"
import CTA from "../components/CTA/CTA"
import { Component } from "react"
import HiddenHeading from "../components/HiddenHeading/HiddenHeading"
import BorderedHeading from "@website/components/BorderedHeading/BorderedHeading"
import LinkWithArrow from "@website/components/LinkWithArrow/LinkWithArrow"
import AllRacesButtonRow from "@website/components/AllRacesButtonRow/AllRacesButtonRow"
import ArrowSmallRightIcon from "@website/svg/icons/ArrowSmallRightIcon"

interface Props {
  loaded: boolean
  races: RaceOverview[]
  highlightedRace?: RaceOverview
}

export default class HomePage extends Component<Props> {
  static defaultProps: Props = {
    loaded: false,
    races: []
  }

  static getInitialProps = initialPropsWrapper(async () => {
    const allRaces = await ServerCall.callOrThrow<unknown, RaceOverview[]>(webApi.home.allRaces, {})
    const highlightedRace = allRaces.filter(r => r.status === RaceStatus.Published)[0]
    const hasHighlightedRace = highlightedRace != null
    const raceCount = hasHighlightedRace ? 7 : 6
    const displayCount = allRaces.length >= raceCount ? 6 : 3
    const startIndex = hasHighlightedRace ? 1 : 0
    const races = allRaces.slice(startIndex, startIndex + displayCount)
    return { races: races, highlightedRace, loaded: true }
  })

  render() {
    return (
      <Layout paddingOnMobile="medium">
        <Meta title="Registrace na závody" description="Registrace na závody a sportovní akce, startovní listiny a profilové stránky závodů" />
        <CanonicalUrl relativeUrl={routes.home.getLink()} />
        <HiddenHeading />
        <Container>
          <BorderedHeading>
            <Heading type="h2" size="h1" brand>
              Registrujte se na závody
            </Heading>
            <LinkWithArrow arrow="right" hiddenOnMobile to={routes.allRaces.getLink()}>
              Zobrazit všechny závody
            </LinkWithArrow>
          </BorderedHeading>
        </Container>
        {this.props.highlightedRace && <HighlightedRace race={this.props.highlightedRace} />}
        <Container>{this.props.loaded ? this.renderContent() : this.renderLoader()}</Container>
        <CTA />
      </Layout>
    )
  }

  private renderContent() {
    return (
      <>
        <div className="grid gap-y-4 md:grid-cols-2 md:gap-8 xl:grid-cols-3 xl:gap-10">
          {([...this.props.races] || []).map(race => (
            <RaceCard key={race.id} race={race} />
          ))}
        </div>
        <AllRacesButtonRow>
          <ButtonLink to={routes.allRaces.getLink()} style="outlined">
            Zobrazit všechny závody
            <ArrowSmallRightIcon classnames="w-6 h-6" />
          </ButtonLink>
        </AllRacesButtonRow>
      </>
    )
  }

  private renderLoader() {
    return <PageContentLoader />
  }
}
