import classNames from "classnames"

const borderedHeadingClasses = classNames(`mb-3 flex flex-wrap items-baseline justify-between gap-x-8 gap-y-1 border-b border-gray-200 pb-2 dark:border-b-2 dark:border-gray-100/10 xs:mb-5 xl:mb-6`)
const invertedClasses = classNames(`border-red-500`)
const largeMarginBottomClasses = classNames(`xs:mb-5`)
const smallMarginBottomClasses = classNames(`mb-3 xs:mb-3 lg:mb-6`)
const stickyHeadingClasses = classNames(
  `sticky top-0 z-10 -mx-4 -mt-3 gap-y-0 rounded-t-xl bg-white/70 px-4 pt-2 backdrop-blur dark:bg-slate-950/80 sm:static sm:mx-0 sm:mt-0 sm:bg-transparent sm:px-0 sm:pt-0 sm:backdrop-blur-0 sm:dark:bg-transparent`
)

const marginBottomClassesMap = {
  small: smallMarginBottomClasses,
  large: largeMarginBottomClasses
}

interface BorderedHeadingProps {
  children: React.ReactNode
  marginBottomMobile?: "small" | "large"
  sticky?: boolean
  inverted?: boolean
}

export default function BorderedHeading({ children, marginBottomMobile = "large", sticky, inverted }: BorderedHeadingProps) {
  return <div className={classNames(borderedHeadingClasses, marginBottomClassesMap[marginBottomMobile], sticky && stickyHeadingClasses, inverted && invertedClasses)}>{children}</div>
}
