import classNames from "classnames"

const borderedClasses = classNames(` border-b border-gray-100 px-4 py-2 dark:border-gray-100/10`)
const detailClasses = classNames(`mb-1 pl-0 text-base md:pl-0`)
const highlightedClasses = classNames(`md:pl-8 md:text-base`)
const raceTagsClasses = classNames(`flex flex-wrap text-sm`)
const tagClasses = classNames(`not-last:after:mx-2 not-last:after:text-gray-300 not-last:after:content-['|']`)

interface RaceTagsProps {
  bordered?: boolean
  detail?: boolean
  highlighted?: boolean
  tags: string[]
}

export default function RaceTags({ tags, highlighted, bordered, detail }: RaceTagsProps) {
  return (
    <ul className={classNames(raceTagsClasses, bordered && borderedClasses, highlighted && highlightedClasses, detail && detailClasses)}>
      {tags.map(tag => (
        <li className={tagClasses} key={tag}>
          {tag}
        </li>
      ))}
    </ul>
  )
}
