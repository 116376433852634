import { formatDate, formatShortDate, formatTime } from "utils"
import { calculateRaceDateRange, type SubraceDatesOnly } from "./raceDateRange"

interface Props {
  showTime?: boolean
  raceDate: Date | number
  subraces: SubraceDatesOnly[]
}

export const RaceDate: React.FC<Props> = ({ raceDate, showTime, subraces }) => {
  const { endsWithDifferentDay, minStartDate, maxEndDate } = calculateRaceDateRange(raceDate, subraces)

  if (!endsWithDifferentDay) {
    if (!showTime) {
      return <>{formatDate(raceDate)}</>
    }

    if (minStartDate && maxEndDate) {
      return (
        <>
          {formatDate(raceDate)} {formatTime(minStartDate)}–⁠{formatTime(maxEndDate)}
        </>
      )
    }
  }

  if (!showTime && minStartDate && maxEndDate) {
    return (
      <>
        {formatShortDate(minStartDate)} – ⁠{formatDate(maxEndDate)}
      </>
    )
  }

  if (minStartDate && maxEndDate) {
    return (
      <>
        {formatShortDate(minStartDate)} – {formatDate(maxEndDate)}
      </>
    )
  }

  return <>{formatDate(raceDate)}</>
}
